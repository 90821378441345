import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import PrivacyPolicy from '../PrivacyPolicy'
import history from '../../util/history'
import TermsOfUse from '../TermsOfUse'
import HomePage from '../HomePage'
import NoticesPage from '../NoticesPage'
import store from '../../store'
import './index.scss'

const App = () =>
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path='/' component={HomePage} exact />
        <Route path='/privacy' component={PrivacyPolicy} exact />
        <Route path='/terms' component={TermsOfUse} exact />
        <Route path='/notices/:vinOrPlate' component={NoticesPage} exact />
        <Route
          path='/blog'
          component={() => {
            window.location.href = ' https://blog.add123.com/'
            return null
          }}
        />
        <Route
          path='/*'
          component={() => {
            window.location.href = 'https://www.carlocate.com/'
            return null
          }}
        />
      </Switch>
    </Router>
  </Provider>

export default App
